$(function(){
	initDolyami();
	initChatHelp();
	initIndexPage();

	function initChatHelp(){
		var initChatChatra = false;
		$('#chat-help-switcher').change(function(){
			if(initChatChatra){
				return true;
			}
			initChat();
		});

		$('.js-open-help-chat').click(function(){
			if(!$('#chat-help-switcher').prop('checked')){
				$('#chat-help-switcher').prop('checked',true).trigger('change');
			}
			$('[for="open-chat-help-switcher"]').trigger('click');
		});
		function initChat() {
			window.ChatraGroupID = 'My6w96f3Q6ggQu2qa';
			(function (d, w, c) {
				w.ChatraID = 'gi9TfC2eNNz6WEDNq';
				var s = d.createElement('script');
				w[c] = w[c] || function () {
					(w[c].q = w[c].q || []).push(arguments);
				};
				s.async = true;
				s.src = 'https://call.chatra.io/chatra.js';
				if (d.head) d.head.appendChild(s);
			})(document, window, 'Chatra');

			window.ChatraSetup = {
				mode: 'frame',
				injectTo: 'chatra-wrapper' /* id �����, � ������� ����� ������� ��� */
			};

			$('.js-send-question').click(function () {
				Chatra('sendAutoMessage', $(this).text());
			});
		}
	}

	function initDolyami(){
		$('.js-dolyami').click(function (){
			if((+$(this).attr('data-discount')) === 10 ) {
				$('.js-show-discount').fadeIn(0);
			}else{
				$('.js-show-discount').fadeOut(0);
			}
		});
	}

	function initIndexPage(){
		initTopSlider();

		function initTopSlider(){
			$('.js-index-banner').slick({
				responsive: [
					{
						breakpoint: 991,
						settings: {
							dots: true
						}
					},
				]
			});
		}
	}
});